<template>
  <main class="page page-article" v-if="article">
    <BreadcrumbsComponent
      :title="article.title"
      :links="[{ title: 'Пресс-центр', to: { name: 'press-list' } }]"
    />
    <div class="page__container">
      <div class="page__content">
        <aside class="document-aside">
          <a
            :href="article.img"
            @click.prevent="showGallery([article.img])"
            class="document-aside__image"
            v-if="article.img"
          >
            <ImgComponent :img="article.img" width="300" height="300" />
          </a>
          <div class="document-aside__item" v-if="article.published_at">
            <span class="document-aside__item-title">Опубликовано:</span>
            <time class="document-aside__item-content" :datetime="article.published_at | robotDate">
              {{ article.published_at | humanDateShort(false) }}
            </time>
          </div>
          <div class="document-aside__item" v-if="article.category">
            <span class="document-aside__item-title">Тип:</span>
            <span class="document-aside__item-content">{{ article.category.title }}</span>
          </div>
          <div class="document-aside__item">
            <span class="document-aside__item-title">Количество просмотров:</span>
            <span class="document-aside__item-content">{{ count }}</span>
          </div>
          <div class="document-aside__item">
            <span class="document-aside__item-title">Поделиться:</span>
            <div class="document-aside__item-socials">
              <ShareNetwork
                :url="url"
                network="telegram"
                :title="article.meta_title || ''"
                :description="article.meta_description || ''"
                :hashtags="article.meta_keywords || ''"
              >
                <IconComponent category="default" name="tg" />
              </ShareNetwork>
              <ShareNetwork
                :url="url"
                network="whatsapp"
                :title="article.meta_title || ''"
                :description="article.meta_description || ''"
                :hashtags="article.meta_keywords || ''"
              >
                <IconComponent category="default" name="wp" />
              </ShareNetwork>
              <ShareNetwork
                :url="url"
                network="vk"
                :title="article.meta_title || ''"
                :description="article.meta_description || ''"
                :hashtags="article.meta_keywords || ''"
              >
                <IconComponent category="default" name="vk" />
              </ShareNetwork>
            </div>
          </div>
        </aside>
        <div class="page-activity-open__content">
          <div class="page-article__article">
            <EditorJSComponent :text="article | getArticle" v-if="article.description" />
            <ul class="page-article__gallery" v-if="images.length">
              <li v-for="(img, i) in images" :key="i">
                <a :href="img.path" @click.prevent="showGallery(images, i)">
                  <ImgComponent :img="img" width="300" height="300" />
                </a>
              </li>
            </ul>
          </div>
          <h2 v-if="article.files && article.files.length" class="page-article__title">Файлы</h2>
          <ul class="page-article__files" v-if="article.files.length">
            <li v-for="(file, i) in article.files" :key="i">
              <ListDocumentItem :data="file" activity link />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="page__more" v-if="more.length">
      <div class="page__more-container">
        <span class="title">Читайте также</span>
        <div class="page__more-content page__more-content--col-4">
          <ListPressItem :data="article" v-for="(article, i) in more" :key="i" />
        </div>
      </div>
    </div>
  </main>
  <NotFound v-else />
</template>

<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import ListPressItem from "components/ListPressItem.vue";
import IconComponent from "components/IconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import NotFound from "../PageNotFound.vue";
import NEWS_OPEN_PAGE from "gql/pages/NewsOpenPage.graphql";
import GalleryModal from "components/modals/components/GalleryModal.vue";
import ListDocumentItem from "components/ListDocumentItem.vue";
import ADD_VIEW from "gql/mutations/AddView.graphql";

export default {
  name: "PressOpenPage",
  mounted() {
    //Обычный скрол прописанный в роутере в начало страницы не срабатывал, код ниже решил проблему
    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 500);
    this.counterUpdate();
    console.log(this.count, "количество просмотров");
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: NEWS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
          sub_department: route.params.did ? parseInt(route.params.did) : undefined,
          type_id: store.state._types.COUNTER_NEWS_TYPE,
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("news/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  methods: {
    counterUpdate() {
      const variables = {
        id: parseInt(this.$route.params.id),
        type_id: this.$store.state._types.COUNTER_NEWS_TYPE,
      };
      this.$apollo
        .mutate({
          mutation: ADD_VIEW,
          variables: variables,
        })
        .then(({ data }) => {
          if (data && data.AddView) {
            console.log("успешно");
          }
        })
        .catch(({ graphQLErrors }) => {
          this.parseGqlErrors(graphQLErrors);
        });
    },
    showGallery(images, index = 0) {
      this.$store.state._modals = [
        {
          component: GalleryModal,
          options: {
            images,
            index,
          },
        },
      ];
    },
    getDescription(article) {
      try {
        return JSON.parse(article.description);
      } catch {
        return {
          blocks: [
            {
              type: "paragraph",
              data: { text: article.description },
            },
          ],
        };
      }
    },
  },
  computed: {
    images() {
      return this.article.images.filter((i) => !!i.img).map((i) => i.img);
    },
    more() {
      return this.$store.state.news.more_news.data;
    },
    url() {
      return this.$store.state._env.MEDIA_ENDPOINT + this.$route.fullPath;
    },
    article() {
      return this.$store.state.news.news_item;
    },
    count() {
      return this.$store.state.news.item_views_count;
    },
  },
  metaInfo() {
    const meta = this.$store.state.news.news_item;
    return {
      title: meta?.meta_title || meta?.title,
      meta: [
        {
          name: "description",
          content: meta?.meta_description,
        },
        {
          name: "keywords",
          content: meta?.meta_keywords,
        },
      ],
    };
  },
  components: {
    ListDocumentItem,
    NotFound,
    ImgComponent,
    EditorJSComponent,
    IconComponent,
    ListPressItem,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/components/doc-aside.styl"
@import "~@/styles/components/article.styl"
.page-article {
  display grid
  grid-gap 50px
  margin-bottom 0
  +below(1024px) {
    grid-gap 30px
  }

  & .document-aside {
    +below(1024px) {
      margin 0 -30px
      grid-row 2
    }
  }

  &__article {
    display grid
    grid-gap 30px
  }

  &__gallery {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 30px
    padding 0
    list-style none
    width 100%
    +below(1100px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(1024px) {
      grid-template-columns repeat(4, 1fr)
    }
    +below(768px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(580px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(440px) {
      grid-template-columns 1fr
    }

    li {
      display inline-flex

      a {
        display inline-flex
        width: 100%;

        img {
          height 170px
          width 100%
          object-fit cover
          border-radius 10px
        }
      }
    }
  }

  &__title {
    color var(--dark)
  }

  &__files {
    display grid
    grid-gap 15px

    li {
      display inline-flex
      width 100%
    }
  }


  & .page__content {
    max-width var(--max-width)
    width 100%
    display grid
    grid-gap 30px
    align-items start
    grid-template-columns 328px 1fr
    margin-bottom 50px
    +below(1024px) {
      grid-template-columns 1fr
      margin-bottom 30px
    }
  }
}
</style>
