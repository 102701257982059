<template>
  <router-link :to="{ name: 'press-item', params: pressParams }" class="news" v-if="data">
    <ImgComponent :img="data.img" width="300" height="300" class="news__image" />
    <div class="news__meta">
      <time class="news__date" v-if="data.published_at" :datetime="data.published_at | robotDate">
        {{ data.published_at | humanDateShort }}
      </time>
      <span class="news__category" v-if="data.category">{{ data.category.title }}</span>
    </div>
    <span class="news__title">{{ data.title }}</span>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "ListPressItem",
  props: {
    data: Object,
  },
  computed: {
    pressParams() {
      let params = { id: this.data.id };
      if (this.$route.name === "ministry-departments-open") {
        params["did"] = this.$route.params.id;
      }
      return params;
    },
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
.news {
  display grid
  align-content: start;

  &__image {
    height 240px
    width 100%
    border-radius 5px
    border: 1px solid var(--gray-dark);
    object-fit cover
  }

  &__meta {
    display flex
    grid-gap 10px
    flex-wrap wrap
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark);
    margin 15px 0 10px
  }

  &__date {
    opacity: 0.5;
  }

  &__title {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }
}
</style>
